import { checkDefinedValues } from '@utils/checkDefinedValues';
import { deepKeysToSnake } from '@utils/convertCase';
import { omitProps } from '@utils/omitProps';

import { FullUser, IncompleteUser, User } from '../types/userData';
import { RentennialsLicenseUpdateData, RentennialsUserData, RentennialsUserUpdateData } from '../types/userDataSchema';

export const transformUser = (rentennialUser: RentennialsUserData) => {
  const user: User = {
    userData: {
      email: rentennialUser.email,
      firstName: rentennialUser.firstName,
      lastName: rentennialUser.lastName,
      id: rentennialUser._id,
      number: rentennialUser.number,
      provider: rentennialUser.provider,
      fullName: rentennialUser.username,
      birthDate: rentennialUser.birth_date,
      document: rentennialUser.dni,
      pictureUrl: rentennialUser.picture_url ? rentennialUser.picture_url : '',
      phone: rentennialUser.phone
    },
    userContactPoint: {
      city:
        !rentennialUser.contactPoint?.city || rentennialUser.contactPoint.city === 'no-city-set'
          ? ''
          : rentennialUser.contactPoint.city,
      province:
        !rentennialUser.contactPoint?.province || rentennialUser.contactPoint.province === 'no-province-set'
          ? ''
          : rentennialUser.contactPoint.province,
      street:
        !rentennialUser.contactPoint?.street || rentennialUser.contactPoint.street === 'no-set'
          ? ''
          : rentennialUser.contactPoint.street,
      streetNumber:
        !rentennialUser.contactPoint?.street_number || rentennialUser.contactPoint.street_number === 'no-set'
          ? ''
          : rentennialUser.contactPoint.street_number,
      id: rentennialUser.contactPoint?._id || ''
    },
    userAddress: {
      country: rentennialUser.country
    },
    userDrivingLicense: {
      emissionDate: rentennialUser.license?.emission_date,
      expirationDate: rentennialUser.license?.expired_date,
      images: rentennialUser.license?.images.map(({ url, type, filename }) => ({ type, url, filename })) || []
    },
    userVerification: {
      isVerified: rentennialUser.is_verified,
      verfiedDate: rentennialUser.verified_date,
      identityVerificationStatus: rentennialUser.stripe.identity_verification_status
    },
    userPublication: {
      isPublishEnabled: rentennialUser.published_enabled,
      publishDate: rentennialUser.published_date
    },
    completionType: 'incomplete'
  };

  const testUser = omitProps(user, 'userContactPoint');
  if (checkDefinedValues(testUser)) {
    return {
      ...user,
      completionType: 'full'
    } as FullUser;
  }

  return user;
};

export const transformToRentennialsUser = (user: IncompleteUser) => {
  const { userAddress, userData, userContactPoint, userDrivingLicense, userPublication, userVerification } = user;
  //TODO convert to proper type
  const images = userDrivingLicense.images.reduce(
    (acc, { imageId, type, filename, url }) => (imageId ? [...acc, { imageId, type, filename, url }] : acc),
    [] as RentennialsLicenseUpdateData['images']
  );

  const rentennialsUser: RentennialsUserUpdateData = {
    email: userData.email,
    firstName: userData.firstName,
    lastName: userData.lastName,
    number: userData.number,
    provider: userData.provider,
    username: userData.fullName,
    birth_date: userData.birthDate,
    dni: userData.document,
    picture_url: userData.pictureUrl,
    contactPoint: deepKeysToSnake(userContactPoint),
    city: userAddress.city,
    country: userAddress.country,
    postal_code: userAddress.postalCode,
    province: userAddress.province,
    street: userAddress.street,
    phone: userAddress.phone,
    license: {
      emission_date: userDrivingLicense.emissionDate,
      expired_date: userDrivingLicense.expirationDate,
      license_number: userDrivingLicense.licenseNumber,
      images
    },
    is_verified: userVerification.isVerified,
    verified_date: userVerification.verfiedDate,
    stripe: {
      identity_verification_status: userVerification.identityVerificationStatus
    },
    published_enabled: userPublication.isPublishEnabled,
    published_date: userPublication.publishDate
  };

  return rentennialsUser;
};
