import { useTranslation } from 'react-i18next';
import { WEB_SITE_DOMAIN } from '@constants/index';
import Head from 'next/head';
import { useRouter } from 'next/router';

const RentennialsHead = () => {
  const { t } = useTranslation('landing');
  const router = useRouter();
  const canonicalUrl = `${WEB_SITE_DOMAIN}${router.asPath}`;
  return (
    <Head>
      <title>Rentennials - Alquiler de autos | Rent a car</title>
      <link rel="shortcut icon" href="/static/favicon.ico" />
      <link rel="canonical" href={canonicalUrl} />
      <meta name="keywords" content={t('landing.metadata.keywords')} />
    </Head>
  );
};

export default RentennialsHead;
