import { DeepRequired } from 'react-hook-form';

export enum StripeStatus {
  REJECTED = 'REJECTED',
  PROCESSING = 'PROCESSING',
  VERIFIED = 'VERIFIED',
  NEED_VERIFICATION = 'NEED_VERIFICATION',
  INCOMPLETE = 'INCOMPLETE'
}

export type UserCompletionType = 'incomplete' | 'full';

interface UserData {
  fullName?: string;
  firstName?: string;
  lastName?: string;
  pictureUrl?: string;
  // might be an enum but not user about apple
  provider: string;
  number: number;
  id: string;
  email: string;
  document?: string;
  birthDate?: string;
  phone?: string;
}

export interface UserContactPoint {
  city?: string;
  country?: string;
  province?: string;
  street?: string;
  streetNumber?: string;
  id: string;
}

export interface UserAddress {
  street?: string;
  city?: string;
  province?: string;
  postalCode?: string;
  country?: string;
  phone?: string;
}

export interface UserDrivingLicense {
  emissionDate?: string;
  expirationDate?: string;
  licenseNumber?: number | string;
  images: {
    type: 'front' | 'back';
    url: string;
    imageId?: string;
    filename: string;
  }[];
}

export interface UserVerification {
  identityVerificationStatus: StripeStatus;
  isVerified: boolean;
  verfiedDate?: string;
}

export interface UserPublication {
  isPublishEnabled: boolean;
  publishDate?: string;
}

interface BaseUser {
  userData: UserData;
  userContactPoint: UserContactPoint;
  userAddress: UserAddress;
  userDrivingLicense: UserDrivingLicense;
  userVerification: UserVerification;
  userPublication: UserPublication;
  completionType: UserCompletionType;
}

export interface IncompleteUser extends BaseUser {
  completionType: 'incomplete';
}

export interface FullUser extends DeepRequired<BaseUser> {
  completionType: 'full';
}

export type User = IncompleteUser | FullUser;

export enum CompletionProfileStatus {
  NONE = 'NONE',
  COMPLETED = 'COMPLETED',
  UNDER_REVIEW = 'UNDER_REVIEW',
  VERIFIED = 'VERIFIED'
}

export interface BasicProfileFormData {
  name?: string;
  lastName?: string;
  email: string;
  country?: string;
  document?: string;
  birthDate?: string;
  phone?: string;
  status: CompletionProfileStatus;
}

export interface RentProfileFormData {
  document?: string;
  birthDate?: string;
  phone?: string;
  licenseNumber?: number | string;
  emissionDate?: string;
  expirationDate?: string;
  status: CompletionProfileStatus;
}

export interface LicenseProfileData {
  emissionDate?: string;
  expirationDate?: string;
  frontImage?: string;
  backImage?: string;
  images?: {
    type: 'front' | 'back';
    filename: string;
    imageId: string;
  }[];
  status: CompletionProfileStatus;
}

export interface UserProfile {
  basicData: BasicProfileFormData;
  licenseData: LicenseProfileData;
  profileReviewStatus: CompletionProfileStatus;
}

export interface LicenseUpdateImage {
  type: 'front' | 'back';
  filename: string;
  imageId: string;
}

export interface UserProfileUpdate extends Omit<UserProfile, 'licenseImages'> {}
